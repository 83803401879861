/*

    This library is created by Franco(Xufei Sun) for Articulate Pty Ltd on 23/03/2017
    All rights reserved.

    This library is based on pure JavaScript; Main intention is to be used without any dependency to increase speed and performance

*/
'use strict';

var errorMsgReader, isJson, ssExtend, lsExtend, regi, dataRetriever, mn, urlToObj, iS;

iS = (function() {
    var _array;

    _array = function(data) {
        if (data && Array.isArray(data)) {
            if (data.length > 0) return data.length;
            else return true;
        }
        else return false;
    }

    return {
        _array: _array
    }
})();



mn = (function() {
    var init, num;

    var stateMap = {
        sign: '¥'
    }

    init = function(sign) {
        stateMap.sign = sign;
    }
    num = function(number) {
        if (isNaN(number)) return 'N/A';

        number = Number(number).toFixed(2);
        return stateMap.sign + number;
    }
    // numericObj = function(obj) {
    //     for (var key in obj) {
    //         if (obj.hasOwnProperty(key)) {
    //             if (!isNaN(obj[key]) && obj[key]!=='' && obj[key]!==true) obj[key] = num(obj[key]);
    //         }
    //     }
    // }

    return {
        init: init,
        num: num,
        // numericObj: numericObj
    }
})();


/* 只适用于 只需要回复的内容去render一些独立的东西 而不与jQuery／其他plugin打交道的情况 */
dataRetriever = (function() {
    // stores all requests for future use
    var requests = {}, handlers = {};

    var get, stopAll, test; //public 

    test = function() {
        console.log(requests);
        console.log(handlers);
    }
    get = function(api, success, error) {
        var tmp_xhttp;
        var tmp_xhttp = new XMLHttpRequest();

        // register callbacks and ajax itself
        requests[api] = tmp_xhttp;
        handlers[api] = {
            success: success,
            error: error
        }

        tmp_xhttp.onreadystatechange = function() {

            if (this.readyState == 4) {
                if (this.status == 200) {

                    // success function may be faulty
                    try {
                        handlers[api].success(this.responseText);
                    }
                    catch(err) {
                        console.error(err.message);
                    }
                    
                }
                else {

                    // error function may be faulty
                    try {
                        handlers[api].error(this.responseText);
                    }
                    catch(err) {
                        console.error(err.message);
                    }
                    
                }

                delete requests[api];
                delete handlers[api];
            }

        };

        tmp_xhttp.open("GET", api, true);
        tmp_xhttp.send();
    }

    stopAll = function() {
        for (var item in requests) {
            requests[item].abort();
            delete requests[item];
        }
        handlers = {};
    }

    return {
        get: get,
        stopAll: stopAll,
        test: test
    }

})();

ssExtend = (function() {
    var set, get;

    set = function(key, value) {
        if (!value || value == 'null') sessionStorage[key] = false;
        else sessionStorage[key] = JSON.stringify(value);
    }

    get = function(key) {
        if (!sessionStorage[key]) return null;
        return JSON.parse(sessionStorage[key]);
    }

    return {
        set: set,
        get: get
    }

})();

lsExtend = (function() {
    var set, get;

    set = function(key, value) {
        if (!value || value == 'null') localStorage[key] = false;
        else localStorage[key] = JSON.stringify(value);
    }

    get = function(key) {
        if (!localStorage[key]) return null;
        return JSON.parse(localStorage[key]);
    }

    return {
        set: set,
        get: get
    }

})();

regi = (function() {
    var data = {},
        set, get;

    set = function(key, value) {
        data[key] = value;

        return value;
    }

    get = function(key) {
        //if (data[key]) 
        return data[key];
        //else console.error('[regi] ' + key + ' not found!');
    }

    return {
        set: set,
        get: get
    }

})();



isJson = function(str) {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}

errorMsgReader = function(rawMsg) {
    var msgBuffer, dataBuffer, counter = 1,
        outputBuffer = '错误！\n'; //string的形式；便于被serverMsgHandler使用 也便于读取

    console.log(rawMsg);

    //兼容Angular
    dataBuffer = rawMsg.responseText ? rawMsg.responseText : rawMsg.data ? rawMsg.data : null;


    // if response is HTML
    if (/<[a-z][\s\S]*>/i.test(dataBuffer)) {
        return 'Oops! Please try again later';
    }

    if (typeof rawMsg === 'object') {
        // 回复是封装好了的

        // data部分是json string
        if (isJson(dataBuffer)) {
            // 1, responseText contains JSON
            msgBuffer = JSON.parse(dataBuffer);
            if (!msgBuffer) msgBuffer = {};

            // 后端报错信息：以message包裹的json
            if (Object.keys(msgBuffer).length == 1) msgBuffer = msgBuffer.message;

            if (typeof msgBuffer === 'object') {
                for (var key in msgBuffer) {
                    if (msgBuffer.hasOwnProperty(key)) {
                        outputBuffer += ((counter++).toString() + '.' + key + ' -- ' + msgBuffer[key] + '\n');
                    }
                }
            } else {
                //string
                outputBuffer = msgBuffer;
            }
        } else if (typeof dataBuffer === 'object') {
            // 2, data部分仍是object
            for (var key in dataBuffer) {
                if (dataBuffer.hasOwnProperty(key)) {
                    outputBuffer += ((counter++).toString() + '.' + key + ' -- ' + dataBuffer[key] + '\n');
                }
            }
        } else {
            // 3, data部分是一个普通的 string
            outputBuffer += '-> ' + dataBuffer;
        }

    } else if (typeof rawMsg === 'string') {
        
        // 返回的事JSON string
        // if (isJson(rawMsg)) {
        //     return errorMsgReader(JSON.parse(rawMsg));
        // }

        // 回复的是string； 这个估计是后端echo回来的错误
        outputBuffer += rawMsg;
    } else alert('未知类型！');

    // 对汉字的处理
    var r = /\\u([\d\w]{4})/gi;
    outputBuffer = outputBuffer.replace(r, function(match, grp) {
        return String.fromCharCode(parseInt(grp, 16));
    });
    outputBuffer = unescape(outputBuffer);


    // console.log(outputBuffer);
    return outputBuffer;
}


urlToObj = function(url) {
    if (!url || typeof url!='string') return {};
    if (url.startsWith('?')) url = url.slice(1);

    var tmp_array=[], result = {}, reg1, reg2;
    tmp_array = url.split('&');

    for (var i=0; i<tmp_array.length; i++) {
        if (tmp_array[i].indexOf('=') >= 0) {
            // 假设只会有一个等号 - 如果有多个也当只有一个处理 因为无法判断
            reg1 = tmp_array[i].split('=');
            reg2 = reg1[1];
            reg1 = reg1[0];
            result[ reg1 ] = reg2;
        }
        // 没有=的话就不做操作
    }
    return result;
}





